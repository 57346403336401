import React from "react";
import "../../hero.css";

function hero() {
  return (
    <section className="hero">
      <div className="hero-image">
          <img src="logo192.png"  alt="Spend Tracker logo" />
      </div>
      <h1>My Money - Track your spending</h1>
      <p>Take control of your finances, this easy-to-use expense tracking app that helps you stay on top of your spending. Log your purchases, set budgets, and get insights into your financial habits—all in one place. Whether you're saving for a big goal or just want to keep an eye on your daily expenses, My Money - Track your spending makes it simple and stress-free. Start tracking smarter today!</p>
      
      <div className="hero-buttons">
            <a href='https://play.google.com/store/apps/details?id=com.spendtracker'><img class="google" alt='Get it on Google Play' src='google.png'/></a>
            <a href='https://apps.apple.com/app/id1566779043'><img class="apple" alt='Get it on IOS' src='apple.png'/></a>
      </div>
    </section>
  );
}

export default hero;
