import React, { Component } from 'react';
import Hero from '../pages/hero';
import Footer from '../footerComponent/footer';

class Terms extends Component {
  render() {
    return (
      <div>
        <Hero /> 
        <h1 className="title">Terms of Service</h1>
        <h2>Is the App Free?</h2>
          <p>Currently the app is free and will remain that was for the forseable future.</p>
        <h2>What about my data?</h2>
        <p>You are responsible for your own mobile data limits when using the app, the following will use data.</p>
          <ul>
            <li>
            Generative ai functionality
            </li>
            <li>
            Clicking links to external websites
            </li>
          </ul>
        <Footer />
      </div>
    )
  }
}

export default Terms;