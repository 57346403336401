import React, { Component } from 'react';
import "../../footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div>
          <p>All rights reserved, Copyright 2025, Powered by Jadder Development House</p>
          <nav>
              <a href ="/">Home</a>|<a href="About">About Us</a>|<a href="privacy">Privacy Policy</a> | <a href="terms">Terms of Use</a>|<a href="contact">Contact</a>
          </nav>
        </div>
      </footer>
    )
  }
}

export default Footer;