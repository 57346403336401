import React, { Component } from 'react';
import Hero from './hero';
import Footer from '../footerComponent/footer';

class About extends Component {
  render() {
    return (
      <div class="home">
        <Hero />
        <h1 class="title">Our Story</h1>
        <p>We believe tracking your spending should be a joyful and accessible journey for everyone. Our story began with a simple yet heartfelt mission: to help my family save for the important things in life. What started as a personal effort to bring clarity to our finances has grown into a tool designed to empower people everywhere to achieve their financial goals—big or small. Let’s make every dollar count, together.</p>
        <Footer />
      </div>
    )
  }
}

export default About;