import React from "react";
import "../../features.css";

function Features() {
  const features = [
    {
      title: "Track Income",
      description: "Easily log your income to see where your money comes from.",
      icon: "💵",
    },
    {
      title: "Track Spending",
      description: "Monitor your expenses and manage your budget effortlessly.",
      icon: "💳",
    },
    {
      title: "Track Goals",
      description: "Set financial goals and track your progress toward achieving them.",
      icon: "🎯",
    },
    {
      title: "Multiple Languages",
      description: "Support for multiple languages and currencies.",
      icon: "🌍",
    },
  ];

  return (
    <section id="features" className="features">
      <h2>Features</h2>
      <div className="feature-cards">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon" aria-label={feature.title}>
              {feature.icon}
            </div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Features;
